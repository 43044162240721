
	import PageBase from '@/Page/Base.vue';
	import { Component, Ref } from 'vue-property-decorator';

	/**
	 * This object is not typically used on its own. It's subclassed
	 * by Day, Month, and Year.
	 *
	 * @author Matt Kenefick <matt.kenefick@buck.co>
	 * @package Page
	 * @project BunnyDragon
	 */
	@Component
	export default class PageBirthdate extends PageBase {
		/**
		 * @param TDispatcherEvent<IMotionEvent> e
		 * @return Promise<void>
		 */
		protected async Handle_OnMotionStart(e: any): Promise<void> {
			if (e.data.from === 'PageBirthdateConfirmation') {
				this.animateOut();
			}
		}
	}
