var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('section',{staticClass:"page-birthdate",class:[
		..._vm.className,
		{
			'show-day': _vm.$route.name === 'PageBirthdateDay',
			'show-month': _vm.$route.name === 'PageBirthdateMonth',
			'show-year': _vm.$route.name === 'PageBirthdateYear',
			'show-confirmation': _vm.$route.name === 'PageBirthdateConfirmation',
			'state-redo': _vm.$store.state.userBirthdateRedo,
		},
	]},[_c('section',{staticClass:"birthdate-container"},[_vm._t("default")],2),_c('section',{staticClass:"display-container"},[_c('ViewBirthdateDisplay')],1),(this.$route.name === 'PageBirthdateConfirmation')?_c('ViewBirthdateConfirmation',{on:{"confirm":_vm.Handle_OnConfirm,"reset":_vm.Handle_OnReset}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }